type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = Omit<AnchorProps, 'href' | 'target'> & {
  to: string;
  newTab?: boolean;
};

export const Link = (props: Props) => {
  const { to, children, newTab, ...rest } = props;
  return (
    <a
      className="underline underline-offset-4 hover:text-primary"
      href={to}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
      {...rest}>
      {children}
    </a>
  );
};
