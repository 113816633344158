import { LayoutFullScreenCentered } from '@client/components/layout-full-screen-centered';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { createFileRoute, redirect } from '@tanstack/react-router'

const AcceptInvite = () => {
  return (
    <LayoutFullScreenCentered>
      <TypographyP>This invite has already been redeemed or expired.</TypographyP>
    </LayoutFullScreenCentered>
  )
}

export const Route = createFileRoute('/invite/accept/$code')({
  component: AcceptInvite,
  beforeLoad: async ({ context, params }) => {
    const { success } = await context.trpcUtils.redeemInvite.fetch(params.code);
    if(success) {
      return redirect({
        to: '/',
        replace: true,
      });
    }
  }
})
