import { LayoutFullScreenCentered } from '@client/components/layout-full-screen-centered';
import { trpc } from '@client/lib/trpc';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
// import { trpc } from '@client/lib/trpc';

export interface RouterContext {
  trpcUtils: ReturnType<typeof trpc.useUtils>;
}

const ErrorLayout = (props: { error: Error }) => {
  return (
    <LayoutFullScreenCentered>
      <h1>Something went wrong</h1>
      <span>{props.error.message}</span>
    </LayoutFullScreenCentered>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  errorComponent: ({ error }) => <ErrorLayout error={error} />,
  component: () => {
    return (
      <>
        <Outlet />
      </>
    );
  },
});
