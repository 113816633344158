import { confirm } from "@client/lib/confirm";
import { momentLondon, prettyErrorCode } from "@client/lib/helpers";
import { trpc } from "@client/lib/trpc"
import { ClassGroup, ClassId } from "@server/const";
import { Job } from "@server/types";
import { useCallback, useMemo, useState } from "react";
import { toast } from "sonner";

type AddJobInput = {
  day: string;
  from: string;
  to?: string;
  classId: ClassId | ClassGroup;
  childNames: string[];
  adultNames: string[];
}

export const useBooker = (onInvalidate?: () => void) => {

  const [runningJobs, setRunningJobs] = useState<Record<string, boolean>>({});

  const trpcUtils = trpc.useUtils();
  const toggleAutomatedBookingsMutation = trpc.automatedBookings.toggle.useMutation();
  const addJobMutation = trpc.automatedBookings.addJob.useMutation();
  const deleteJobMutation = trpc.automatedBookings.deleteJob.useMutation();
  const runJobMutation = trpc.automatedBookings.runJob.useMutation();

  const invalidateQueries = useCallback(() => {
    onInvalidate?.();
  }, [onInvalidate]);

  const addJob = useCallback(async (job: AddJobInput) => {
    const promise = addJobMutation.mutateAsync(job);
    toast.promise(promise, {
      loading: 'Creating automated booking...',
      success: 'Success',
      error: 'Failed to create',
    });
    await promise;
    invalidateQueries();
  }, [invalidateQueries]);

  const deleteJob = useCallback(async (jobId: string) => {
    const confirmResult = await confirm('Delete automated booking?', {
      title: 'Are you sure?',
      ctaText: 'Delete',
      cancelText: 'Back'
    });
    if(!confirmResult) {
      return;
    }
    const promise = deleteJobMutation.mutateAsync(jobId);
    toast.promise(promise, {
      loading: 'Deleting automated booking...',
      success: 'Success',
      error: 'Failed to delete',
    });
    await promise;
    invalidateQueries();
  }, [invalidateQueries]);

  const runJob = useCallback(async (jobId: string) => {
    const { description } = await trpcUtils.automatedBookings.getRunNowTimes.fetch(jobId);
    const result = await confirm(`Target Date: ${description}`, {
      title: 'Run automated booking?',
      ctaText: 'Run',
      cancelText: 'Back'
    });
    if(!result) {
      return;
    }
    const promise = runJobMutation.mutateAsync(jobId);
    setRunningJobs(prev => ({ ...prev, [jobId]: true }));
    toast.promise(promise, {
      loading: 'Running automated booking...',
      success: 'Success',
      error: (err) => err ? prettyErrorCode(err.message) : 'Failed to run',
    });
    promise.finally(() => {
      invalidateQueries();
      setRunningJobs(prev => ({ ...prev, [jobId]: false }));
    });
  }, [invalidateQueries]);

  const toggleAutomatedBookings = useCallback(async (enabled: boolean) => {
    await toggleAutomatedBookingsMutation.mutateAsync({enabled});
    invalidateQueries();
  }, [invalidateQueries]);

  return {
    runningJobs,
    addJob,
    deleteJob,
    runJob,
    toggleAutomatedBookings,
  }
}