import moment, { Moment } from "moment-timezone";
import { CLASSES, CATEGORIES, ClassId, ErrorCode, BOOKING_WINDOW_IN_WEEKS, MINIMUM_TIME_ALLOWANCE_FROM_NOW_IN_HOURS, RETRYABLE_ERROR_CODES, HH_MM, YYYY_MM_DD_HH_MM, ClassGroup, CLASS_GROUPS, TZ_LONDON } from "@server/const";
import { Account, Duration, ExistingBooking, IcsEvent, Job } from "@server/types";

const CATEGORY_NAMES = Object.values(CATEGORIES).map((name) => name.toLowerCase().trim());
const CLASS_NAMES = Object.entries(CLASSES).map(([id, {name}]) => name.toLowerCase().trim());

// 7 September 9:30 am – 10:00 amBaby Ballet - 2 - 4 yrs Eleanor TurnbullEleanor Turnbull 9:30 am – 10:00 amView detailsCancel bookingView detailsCancelEleanor Turnbull

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const momentLondon = (date?: string, format?: string) => {
  if(date && format) {
    return moment.tz(date, format, true, TZ_LONDON);
  } else if(date) {
    return moment.tz(date, TZ_LONDON);
  } else {
    return moment.tz(TZ_LONDON);
  }
}

export const extractTime = (text: string | null | undefined): string | undefined => {
  if(!text) {
    return undefined;
  }
  if(text.includes('am')) {
    const result = validateTime(text.toLowerCase().trim().split('am')[0].trim());
    if(result.valid) {
      return result.resolved;
    }
  }
  if(text.includes('pm')) {
    const timeStr = text.toLowerCase().trim().split('pm')[0].trim();
    const [hours, minutes] = timeStr.split(':').map(Number);
    const hoursToAdd = hours === 12 ? 0 : 12;
    const time = `${hours + hoursToAdd}:${minutes}`;
    const result = validateTime(time);
    if(result.valid) {
      return result.resolved;
    }
  }
}

export const extractBookingDetails = (text: string) => {
  // return {
  //   date,
  //   startTime,
  //   endTime,
  //   classId,

  // }
}

export const isClass = (text: string | null | undefined) => {
  if(!text) {
    return false;
  }
  return CLASS_NAMES.includes(text?.split('with')[0]?.toLowerCase()?.trim());
}

export const matchClassId = (text: string | null | undefined): ClassId | undefined => {
  if(!text) {
    return undefined;
  }
  let classId: ClassId | undefined = undefined;
  for(const [id, {name, aliases=[], excludeTerms=[]}] of Object.entries(CLASSES)) {
    const textCleaned = text.toLowerCase().trim();
    const withoutAge = textCleaned.split('-')[0].trim();
    const withoutWith = withoutAge.split('with')[0].trim();
    const withoutBrackets = withoutWith.split('(')[0].trim();
    // console.log({
    //   name,
    //   textCleaned,
    //   withoutAge,
    //   withoutWith,
    //   withoutBrackets,
    //   nameLower: name.toLowerCase().trim(),
    //   condition: name.toLowerCase().trim() === withoutBrackets,
    // })
    const nameLowerCaseTrimed = name.toLowerCase().trim();
    const aliasesLowerCaseTrimed = aliases.map(alias => alias.toLowerCase().trim());
    const excludeTermsLowerCaseTrimed = excludeTerms.map(term => term.toLowerCase().trim());
    const hasNameMatch = nameLowerCaseTrimed === withoutBrackets;
    const hasAliasMatch = aliasesLowerCaseTrimed.includes(withoutBrackets);
    const hasExcludeTermMatch = excludeTermsLowerCaseTrimed.includes(withoutBrackets);
    if((hasNameMatch || hasAliasMatch) && !hasExcludeTermMatch) {
      // logger.debug('matchClassId match found', {
      //   name,
      //   aliases,
      //   excludeTerms,
      //   hasNameMatch,
      //   hasAliasMatch,
      //   hasExcludeTermMatch,
      //   withoutBrackets,
      // });
      classId = id as ClassId;
      break;
    }
  }

  return classId;
}

export const isCategory = (text: string | null | undefined) => {
  if(!text) {
    return false;
  }
  return CATEGORY_NAMES.includes(text?.toLowerCase()?.trim());
}

export const validateTime = (time?: string): {
  valid: false;
} | {
  valid: true;
  resolved: string;
} => {
  if (!time) {
    return {
      valid: false,
    };
  }
  // regex test for HH:MM
  const regex = /^(\d{1,2}):(\d{2})$/;
  const match = time.match(regex);
  if(!match) {
    return {
      valid: false,
    };
  }
  const frags = time.split(':').map(frag => frag.trim()).filter(Boolean);
  if(frags.length !== 2) {
    return {
      valid: false,
    };
  }
  const [hours, minutes] = frags.map(Number);
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return {
      valid: false,
    };
  }
  if(typeof hours !== 'number' || typeof minutes !== 'number') {
    return {
      valid: false,
    };
  }
  return {
    valid: true,
    resolved: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
  };
}

export const isRetryableError = (error: unknown): boolean => {
  if(error instanceof Error) {
    const message = error.message;
    const isErrorCode = Object.values(ErrorCode).includes(message as ErrorCode);
    // we want to retry unknown errors
    const isRetryable = !isErrorCode || RETRYABLE_ERROR_CODES.includes(message);
    return isRetryable
  }
  return false;
}

export const calcDuration = (from: string, to: string, units: 'hours' | 'minutes' | 'seconds'): number => {
  const [fromHours, fromMinutes] = from.split(':').map(Number);
  const [toHours, toMinutes] = to.split(':').map(Number);
  const totalMinutes = (toHours * 60 + toMinutes) - (fromHours * 60 + fromMinutes);
  const result = units === 'hours' ? totalMinutes / 60 : units === 'minutes' ? totalMinutes : totalMinutes * 60;
  return Number(result.toFixed(1));
}

export const prettyErrorCode = (errorCode: ErrorCode | string, fallback?: string): string => {
  // Turn THIS_IS_AN_ERROR into This Is An Error
  if(Object.values(ErrorCode).includes(errorCode as ErrorCode)) {
    return errorCode
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  return fallback ?? errorCode;
}

/*

BOOKING WINDOW = 1 WEEK

On Tuesday, configuring job for Wednesday
Outcome: 
  nextRunAt = this coming wednesday
  nextBookingDate = next wednesday

On Wednesday, configuring job for Monday
Outcome: 
  nextRunAt = next monday
  nextBookingDate = monday after next week

*/

export const getTargetDate = (job: Pick<Job, 'day' | 'from'>, weeksOut: number, minimumHoursFromNow: number, _refMoment?: Moment): Date => {
  const [hour, minute] = job.from.split(':').map(Number);
  const now = _refMoment || momentLondon();
  const targetDay = now
    .clone()
    .startOf('isoWeek')
    .isoWeekday(job.day)
    .set({
      hour,
      minute,
    });

  if(targetDay.isBefore(now)) {
    weeksOut += 1;
  }

  // apply weeksOut
  targetDay.add(weeksOut, 'week');

  // check target date is not within the next minimumHoursFromNow hours
  if(targetDay.isBefore(now.clone().add(minimumHoursFromNow, 'hours'))) {
    targetDay.add(1, 'week');
  }

  return targetDay.toDate();
}

export const getNextRunAt = (job: Pick<Job, 'day' | 'from'>): Date => {
  return getTargetDate(job, 0, 0);
}

export const getNextBookingDate = (job: Pick<Job, 'day' | 'from'>): Date => {
  return getTargetDate(job, BOOKING_WINDOW_IN_WEEKS, MINIMUM_TIME_ALLOWANCE_FROM_NOW_IN_HOURS);
}

export const hasWord = (text: string | null | undefined, _word: string, caseSensitive = false): boolean => {
  if(!text) {
    return false;
  }
  const word = caseSensitive ? _word : _word.toLowerCase();
  const wordsInText = text.split(/\s+/).map(word => word.trim()).map(word => caseSensitive ? word : word.toLowerCase());
  return wordsInText.includes(word);
}

export const getDuration = (job: Pick<Job, 'classId'>): Duration | undefined => {
  const { classId, classGroup } = safeParseClassId(job);
  if(classGroup) {
    // duration not supported for class groups
    return undefined;
  }
  return CLASSES[classId].duration;
}

export const safeParseClassId = (job: Pick<Job, 'classId'>): {
  classId: ClassId;
  classGroup?: undefined;
} | {
  classId?: undefined;
  classGroup: ClassGroup;
} => {
  if(Object.values(ClassId).includes(job.classId as ClassId)) {
    return {
      classId: job.classId as ClassId,
    }
  }
  if(Object.values(ClassGroup).includes(job.classId as ClassGroup)) {
    return {
      classGroup: job.classId as ClassGroup,
    }
  }
  throw new Error('Invalid classId: ' + job.classId);
}

export const getStepsAndCategory = (job: Pick<Job, 'classId'>) => {
  // classGroups must always share the same steps and category
  const { classGroup, classId: _classId } = safeParseClassId(job);
  const classId = classGroup ? CLASS_GROUPS[classGroup].classIds[0] : _classId;
  const { category, steps } =  CLASSES[classId];
  return {
    category,
    steps,
  }
}

export const getClassName = (job: Pick<Job, 'classId'>): string => {
  const { classId, classGroup } = safeParseClassId(job);
  if(classGroup) {
    const { name } = CLASS_GROUPS[classGroup]
    return name;
  } else {
    const { name } = CLASSES[classId];
    return name;
  }
}

export const prettyPrintTime = (job: Job, html?: boolean) => {
  const duration = getDuration(job);
  if(!duration) {
    const time = momentLondon(job.from, HH_MM).format('h:mm a');
    return html ? `<i>${time}</i>` : time;
  }
  else {
    return `${momentLondon(job.from, HH_MM).format('h:mm a')} - ${momentLondon(job.to, HH_MM).format('h:mm a')}`;
  }
}

export function getRelativeTimeFromNow(date: Date): string {
  const now = new Date();
  const diff = date.getTime() - now.getTime();

  const absDiff = Math.abs(diff);
  const diffSeconds = Math.floor(absDiff / 1000);
  const diffMinutes = Math.floor(absDiff / (1000 * 60));
  const diffHours = Math.floor(absDiff / (1000 * 60 * 60));
  const diffDays = Math.floor(absDiff / (1000 * 60 * 60 * 24));

  const isFuture = diff > 0;
  const prefix = isFuture ? 'in ' : '';
  const suffix = isFuture ? '' : ' ago';

  if (diffSeconds < 30) {
    return 'a few seconds' + suffix;
  } else if (diffSeconds < 60) {
    return `${prefix}${diffSeconds} seconds${suffix}`;
  } else if (diffMinutes < 60) {
    return `${prefix}${diffMinutes} minutes${suffix}`;
  } else if (diffHours < 24) {
    return `${prefix}${diffHours} hours${suffix}`;
  } else {
    return `${prefix}${diffDays} days${suffix}`;
  }
}

export function getStatusColor(status: Job['status']) {
  switch (status) {
    case 'healthy':
      return 'green';
    case 'warning':
      return 'orange';
    case 'unhealthy':
      return 'red';
    default:
      return 'gray';
  }
}

export function formatBookingDate(date: Date): string {
  return moment(date).format('dddd, D MMM');
}