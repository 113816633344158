// import { createRouter, RouterProvider } from '@tanstack/react-router';
// import { router } from './router';
// import { trpc } from '@client/lib/trpc';
import { QueryClientProvider } from '@tanstack/react-query';
import { httpLink, loggerLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { Toaster as SonnerToaster } from '@client/components/ui/sonner';
import { Confirm } from '@client/lib/confirm';

import { trpc } from "./lib/trpc";

import { QueryClient } from '@tanstack/react-query';

// import { routeTree } from './routeTree.gen';
// import { RouterContext } from '@client/routes/__root';
// import { Toaster as SonnerToaster } from '@client/components/ui/sonner';
// import { useTheme } from '@client/theme';
import transformer from '@trpc-transformer';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { RouterContext } from './routes/__root';
import { routeTree } from './routeTree.gen';
import { useTheme } from './theme';

export const queryClient = new QueryClient();

const trpcClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: (opts) => {
        return true;
        // return process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error)
      },
    }),
    httpLink({
      url: '/trpc',
      transformer,
    }),
  ],
});

// Create a new router instance
const router = createRouter({ routeTree, context: {} as RouterContext });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function AppWithRoutes() {

  const trpcUtils = trpc.useUtils();

  return (
    <RouterProvider
      router={router}
      context={{
        trpcUtils,
      }}
    />
  );
}

function App() {
  const { theme } = useTheme();

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        <div vaul-drawer-wrapper="" className="bg-background min-h-[100vh]">
          <AppWithRoutes />
          <SonnerToaster theme={theme} />
          <Confirm />
        </div>
    </QueryClientProvider>
    </trpc.Provider>)
}

export default App;
