import { cn } from '@client/lib/utils';
import React from 'react';

export const ButtonGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, className, ...divProps }) => {
  return (
    <div className={cn('flex items-center flex-row flex-wrap gap-2 mt-4', className)} {...divProps}>
      {children}
    </div>
  );
};
