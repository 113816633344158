import { LayoutDefault } from '@client/components/layout-default'
import { Button } from '@client/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@client/components/ui/card';
import { Checkbox } from '@client/components/ui/checkbox';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { Emoji } from '@client/components/ui/custom/emoji';
import { Link } from '@client/components/ui/custom/link';
import { TextAndEmojis } from '@client/components/ui/custom/text-and-emojis';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { TypographyP } from '@client/components/ui/custom/typography-p'
import { Input } from '@client/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@client/components/ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@client/components/ui/tooltip';
import { useAuth } from '@client/hooks/use-auth';
import { prettyErrorCode } from '@client/lib/helpers';
import { trpc } from '@client/lib/trpc';
import { Label } from '@radix-ui/react-dropdown-menu';
import { createFileRoute, redirect, useNavigate, useRouter } from '@tanstack/react-router'
import { TRPCClientError } from '@trpc/client';
import { CircleHelp, Info } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

const HighlightedInfo = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className='bg-highlight p-1 rounded-sm leading-7'>
      {children}
    </span>
  )
}

const Welcome = () => {

  const [step, setStep] = useState<number>(1);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [password, setPassword] = useState('');
  const { email } = Route.useLoaderData();
  const navigate = useNavigate({
    from: Route.fullPath,
  })
  const router = useRouter();
  const completeSetupMutation = trpc.completeSetup.useMutation();

  const handleAcceptedTerms = (checked: boolean) => {
    setAcceptedTerms(checked);
  }

  const handleSubmit = async () => {
    if(!acceptedTerms) {
      toast.error('You must accept the terms and privacy policy to proceed');
      return;
    }
    if(!password || password.trim() === '') {
      toast.error('You must enter your JH password to proceed');
      return;
    }
    const promise = completeSetupMutation.mutateAsync({
      accepted: acceptedTerms,
      password,
    });
    toast.promise(promise, {
      loading: 'Setting up your account...',
      error: err => {
        if(err instanceof TRPCClientError) {
          return err.message;
        }
        return prettyErrorCode(err.message);
      },
    });
    await promise;
    await router.invalidate();
  }

  // return (
  //   <LayoutDefault>
  //     <div className='flex flex-col gap-4 pb-10'>

  //       <div className='flex justify-between items-center mb-6'>
  //         <TypographyP className='font-semibold'>JH Booker</TypographyP>
  //       </div>

  //       { step === 1 && (
  //         <>
  //           <div>
  //             <Emoji lg className='mr-2'>👋</Emoji>
  //             <span className=''>Welcome {email}</span>
  //           </div>

  //           <div>
  //             The <i>'IT guy'</i> here. I hope you enjoy using the booker! It started out as a bit of fun but has actually become incredibly useful, so useful in fact, that I decided to share it with you.
  //           </div>
  //         </>
  //       ) }
  //       { step === 2 && (
  //         <>
  //           <div>
  //             <TypographyH3>Using the Booker</TypographyH3>
  //             <TypographyP>In a nutshell, it's an automated booking engine and calendar integration for Jesse's and Jaego's House members. It's designed to make it easy to develop a routine around the club, booking the classes you want and syncing them with the rest of your schedule.</TypographyP>
  //             <TypographyP>You don't need to use the booker for every class, for example, I haven't bothered mapping member's events as they're adhoc and don't repeat. Not to worry though, bookings you make through the portal will still be picked up and synced in your calendar<Emoji className='ml-1'>😎</Emoji></TypographyP>
  //           </div>
  //         </>
  //       ) }
  //       { step === 3 && (
  //         <>
  //           <div>
  //             <TypographyH3>Using the Calendar Feed</TypographyH3>
  //             <TypographyP>I've built the calendar feed to be permissionless - this means it doesn't require access to your calendar. Instead, you have a unique URL you can subscribe to in your own calendar app. Cool tip, you can also share this with your friends so they can toggle your bookings alongside their own<Emoji className='ml-1'>🥳</Emoji></TypographyP>
  //             <TypographyP>The one catch is there is a delay on how quickly your calendar refreshes the feed. The service will sync your bookings every hour, however, some calendar apps can take up to 6 hours to pick up the changes. <i>*ehem* Google...</i></TypographyP>
  //           </div>
  //         </>
  //       ) }
  //       { step === 4 && (
  //         <>
  //           <div>
  //             <TypographyH3>Activating your account</TypographyH3>
  //             <TypographyP>I wish I could run this for free, however, servers and infrastructure cost money to run and it also requires my time to keep the service up to date with the JH portal. For this reason, you'll need to contribute a <HighlightedInfo>small monthly fee to help cover these costs</HighlightedInfo> (you can do this later though).</TypographyP>
  //           </div>
  //           <div>
  //             <div className='flex items-center gap-2'>
  //               <Checkbox checked={acceptedTerms} onCheckedChange={handleAcceptedTerms} required />
  //               <TypographyP>I accept the <Link to='/legal?tab=terms' newTab>Terms of Service</Link> and <Link to='/legal?tab=privacy' newTab>Privacy Policy</Link>.</TypographyP>
  //             </div>
  //           </div>

  //           <div>
  //             {/* <Label>Enter your JH password</Label> */}

  //               <div className='flex items-center gap-1'>
  //                 <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className='max-w-[250px]' placeholder='Enter your JH password' required />
  //                 <Popover>
  //                   <PopoverTrigger asChild>
  //                     {/* <Button variant={'ghost'} size={'sm'}>Why is this needed?</Button> */}
  //                     <Button variant={'ghost'} size={'icon'}>
  //                       <CircleHelp className='w-4 h-4' />
  //                     </Button>
  //                   </PopoverTrigger>
  //                   <PopoverContent>
  //                     <TypographyP>This is needed in order to log into your account and read your bookings for the calendar feed. If you enable the booker, it willbe used to make bookings on your behalf.</TypographyP>
  //                   </PopoverContent>
  //                 </Popover>
  //               </div>

  //             {/* <TypographyMuted className='text-xs mt-2'>This is need in order to log into your account and read your bookings for the calendar feed. If you enable the booker, these will be used to make bookings on your behalf.</TypographyMuted> */}
  //           </div>

  //           <div>
  //             <Button disabled={completeSetupMutation.isPending} onClick={handleSubmit}>Get Started</Button>
  //           </div>
  //         </>
  //       ) }

  //       <ButtonGroup>
  //         { step > 1 && (
  //           <Button onClick={() => setStep(step - 1)}>Back</Button>
  //         ) }
  //         { step < 3 && (
  //           <Button onClick={() => setStep(step + 1)}>Next</Button>
  //         ) }
  //       </ButtonGroup>
  //     </div>
  //   </LayoutDefault>
  // )

  return (
    <LayoutDefault>
      <div className='flex flex-col gap-4 pb-10'>
        <div className='flex justify-between items-center mb-6'>
          <TypographyP className='font-semibold'>JH Booker</TypographyP>
        </div>

        <div>
          <Emoji lg className='mr-2'>👋</Emoji>
          <span className=''>Welcome {email}</span>
        </div>

        <div>
          The <i>'IT guy'</i> here. I hope you enjoy using the booker! It started out as a bit of fun but has actually become incredibly useful - so useful in fact, that I decided to share it with you.
        </div>

        <div>
          <TypographyH3>Using the Booker</TypographyH3>
          <TypographyP>The Booker is an automated booking engine and calendar feed for Jesse's and Jaego's House members. It's designed to make it easy to develop a routine around the club, booking the classes you want and syncing them with the rest of your schedule.</TypographyP>
          <TypographyP>You don't need to use the booker for every class, for example, I haven't bothered mapping member's events as they're adhoc and don't repeat. Not to worry though, bookings you make through the portal will still be picked up and synced in your calendar<Emoji className='ml-2'>😎</Emoji></TypographyP>
        </div>

        <div>
          <TypographyH3>More on the Calendar Feed</TypographyH3>
          <TypographyP>I've built the calendar integration to be permissionless - this means it doesn't require access to your calendar/events. Instead, you have a unique URL you can subscribe to in your own calendar app. Cool tip, you can also share this with your friends so they can toggle your bookings alongside their own<Emoji className='ml-2'>🥳</Emoji></TypographyP>
          <TypographyP>The one catch is that there is a delay on how quickly your calendar refreshes the feed. The Booker will sync your bookings every hour, however, some calendar apps can take up to 6 hours to pick up the changes. <i>*ahem* Google...</i></TypographyP>
        </div>

        {/* <div>
          <TypographyH3>Activating your account</TypographyH3>
          <TypographyP>I wish I could run this for free, however, servers and infrastructure cost money to run, and it also requires my time to keep the service up to date with the JH portal. For this reason, you'll need to contribute a <HighlightedInfo>small monthly fee to help cover these costs</HighlightedInfo> (You'll have a week to try it out first though, so no stress).</TypographyP>
        </div> */}

        <div>
          <TypographyH3>Getting Started</TypographyH3>
          <TypographyP>The booker works by using a bot to log into your account and make bookings on your behalf. In order to do this, it needs your JH password - You can enter this below.</TypographyP>
        </div>

        <div className='ml-4 my-4'>
          <div className='flex items-center gap-4'>
            <Checkbox checked={acceptedTerms} onCheckedChange={handleAcceptedTerms} required />
            <TypographyP className='mt-0'>I accept the <Link to='/legal?tab=terms' newTab>Terms of Service</Link> and <Link to='/legal?tab=privacy' newTab>Privacy Policy</Link>.</TypographyP>
          </div>
        </div>

        <div>
          {/* <Label>Enter your JH password</Label> */}

            <div className='flex items-center gap-1'>
              <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className='max-w-[328px]' placeholder='Enter your JH password' required />
              <Popover>
                <PopoverTrigger asChild>
                  {/* <Button variant={'ghost'} size={'sm'}>Why is this needed?</Button> */}
                  <Button variant={'ghost'} size={'icon'}>
                    <CircleHelp className='w-4 h-4' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <TypographyP>This is needed in order to log into your account and read your bookings for the calendar feed. If you enable the booker, it will be used to make bookings on your behalf.</TypographyP>
                </PopoverContent>
              </Popover>
            </div>

          {/* <TypographyMuted className='text-xs mt-2'>This is need in order to log into your account and read your bookings for the calendar feed. If you enable the booker, these will be used to make bookings on your behalf.</TypographyMuted> */}
        </div>

        <div>
          <Button disabled={completeSetupMutation.isPending} onClick={handleSubmit}>Complete Setup</Button>
        </div>

        {/* <div>
          <TypographyMuted>
            By proceeding, you agree to the <Link to='/legal?tab=terms' newTab>Terms of Service</Link> and <Link to='/legal?tab=privacy' newTab>Privacy Policy</Link>.
          </TypographyMuted>
        </div> */}

        {/* <div>
          <TypographyH3></TypographyH3>
          <TypographyP></TypographyP>
        </div> */}

        {/* <div>
          <TypographyH3></TypographyH3>
          <TypographyP></TypographyP>
        </div> */}
      </div>
    </LayoutDefault>
  )
}

export const Route = createFileRoute('/_authenticated/welcome')({
  component: Welcome,
  beforeLoad: async ({ context }) => {
    if(!context.session.pendingAccountSetup) {
      return redirect({
        to: '/',
        replace: true,
        throw: true,
      })
    }
  },
  loader: async ({ context }) => {
    return {
      email: context.session.email,
    }
  }
})
