import { createFileRoute, redirect } from '@tanstack/react-router';
import { trpc } from '@client/lib/trpc';
import { Outlet } from '@tanstack/react-router';
import { posthog } from 'posthog-js';
import { useEffect } from 'react';
import { useRouteContext } from '@tanstack/react-router';
import { Session } from '@server/types';
import { PGEvent } from '@server/const';
import { useLoadTidio } from '@client/hooks/use-tidio';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';

const AuthenticatedContainer = () => {
  const { session } = useRouteContext({
    from: '/_authenticated',
  });

  useLoadTidio(session.email);

  useEffect(() => {
    posthog.identify(session.email, {
      $set: {
        email: session.email,
      }
    });
    posthog.capture(PGEvent.APP_VIEWED)
  }, [session]);

  return (
    <div>
      <Outlet />
    </div>
  )
};

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async (ctx) => {
    let session: Session | undefined = undefined;
    try {
      session = await ctx.context.trpcUtils.getSession.fetch();
    } catch(err) {
      // ignore
    }
    if(!session) {
      // redirect to login
      redirect({
        to: '/login',
        replace: true,
        throw: true,
      });
    }
    if(session!.pendingAccountSetup) {
      if(ctx.location.pathname !== '/welcome') {
        redirect({
          to: '/welcome',
          replace: true,
          throw: true,
        });
      }
      return {
        session: session!,
      };
    }
    const accountProfile = await ctx.context.trpcUtils.getAccountProfile.fetch();
    return {
      session: session!,
      accountProfile,
    };
  },
  component: AuthenticatedContainer,
});
