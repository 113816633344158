import { BREAKPOINT_MD, BREAKPOINT_SM } from '@client/lib/const';
import { useWindowSize } from 'usehooks-ts';

export const useScreenSize = () => {
  const { width, height } = useWindowSize();
  const isMobile = width <= BREAKPOINT_SM;
  const isTablet = width <= BREAKPOINT_MD && width > BREAKPOINT_SM;
  const isDesktop = width > BREAKPOINT_MD;

  return {
    width,
    height,
    isMobile,
    isTablet,
    isDesktop,
  };
};
