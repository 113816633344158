// @ts-nocheck

const HOSTNAME = window.location.hostname;

type Config = {
  serverHost: string;
  posthogKey?: string;
  posthogHost?: string;
};

const isLocalHost = HOSTNAME?.includes('localhost') || HOSTNAME?.includes('ngrok');

export const config: Config = isLocalHost ? {
  serverHost: 'http://localhost:3010',
  posthogHost: 'https://eu.posthog.com',
  posthogKey: 'phc_LMc2k4IH1dOAKqISNIxw4dZYY89H3UpFYYsEqep6vhO',
} : {
  serverHost: HOSTNAME,
  posthogHost: 'https://eu.posthog.com',
  posthogKey: 'phc_vgKYgQQnimZMDnrGvpqtQHi4PZrxOmBPgT1RYKX6Arn',
};
