import { cn } from '@client/lib/utils';
import React from 'react';

export const TypographyMuted = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <p {...props} className={cn(`text-sm text-muted-foreground`, props.className)}>
      {props.children}
    </p>
  );
};
