import { LayoutFullScreenCentered } from '@client/components/layout-full-screen-centered'
import { Button } from '@client/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@client/components/ui/card'
import { TypographyMuted } from '@client/components/ui/custom/typography-muted'
import { Input } from '@client/components/ui/input'
import { useAuth } from '@client/hooks/use-auth'
import { createFileRoute, redirect, useRouter } from '@tanstack/react-router'
import { useState } from 'react'

enum TabValue {
  CREATE_ACCOUNT = 'create-account',
  LOGIN = 'login',
}

type Props = {
  email: string
  password: string
  setEmail: (email: string) => void
  setPassword: (password: string) => void
}

const LoginCard = ({ email, password, setEmail, setPassword }: Props) => {

  const router = useRouter();
  const { login } = useAuth(router.invalidate);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await login({ email, password })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Login</CardTitle>
        <CardDescription>Use your Jesse's/Jaego's House email and password.</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '.25rem' }}>
            <label htmlFor="email">JH email:</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '.25rem' }}>
            <label htmlFor="password">JH password:</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <Button
            type="submit"
          >
            Login
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

const Component = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [tab, setTab] = useState<TabValue>(TabValue.CREATE_ACCOUNT);

  const props: Props = {
    email,
    password,
    setEmail,
    setPassword,
  }

  return (
    <LayoutFullScreenCentered>
      <div className='w-[400px] mt-10'>
        {/* <Tabs value={tab} onValueChange={(value) => setTab(value as TabValue)}>
          <TabsList className='grid w-full grid-cols-2'>
            <TabsTrigger value={TabValue.CREATE_ACCOUNT}>Create Account</TabsTrigger>
            <TabsTrigger value={TabValue.LOGIN}>Login</TabsTrigger>
          </TabsList>
          <TabsContent value={TabValue.CREATE_ACCOUNT}>
            <CreateAccountCard {...props} />
          </TabsContent>
          <TabsContent value={TabValue.LOGIN}>
            <LoginCard {...props} />
          </TabsContent>
        </Tabs> */}
        <LoginCard {...props} />
        <div className='mt-4 text-center'>
          <TypographyMuted>
            This is a private service with access via invitation only.
          </TypographyMuted>
        </div>
      </div>
    </LayoutFullScreenCentered>
  )
}

export const Route = createFileRoute('/login')({
  component: Component,
  beforeLoad: async ({ context }) => {
    let session;
    try {
      session = await context.trpcUtils.getSession.fetch();
    } catch(err) {
      // ignore
    }
    if(session) {
      console.log('redirecting to home', session)
      return redirect({
        to: '/',
        throw: true,
      })
    }
  }
})
