import { trpc } from "@client/lib/trpc";
import { TRPCClientError } from "@trpc/client";
import posthog from "posthog-js";
import { useCallback, useEffect } from "react";
import { toast } from "sonner";

function aliasPosthog(email: string) {
  try {
    const distinctId = posthog.get_distinct_id();
    if(distinctId && distinctId !== email) {
      posthog.alias(distinctId, email);
    }
  } catch(err) {
    console.error('Error aliasing posthog', err);
  }
}

export const useAuth = (invalidate?: () => void) => {
  const loginMutation = trpc.login.useMutation();
  const logoutMutation = trpc.logout.useMutation();

  useEffect(() => {
    (window as any).pg = posthog
  }, []);

  const invalidateQueries = useCallback(() => {
    invalidate?.();
  }, [invalidate]);

  const login = useCallback(async (data: { email: string, password: string }) => {
    const promise = loginMutation.mutateAsync(data);
    toast.promise(promise, {
      loading: 'Logging in...',
      error: (err) => {
        if(err instanceof TRPCClientError) {
          return err.message;
        }
        return 'Login failed';
      }
    });
    await promise;
    aliasPosthog(data.email);
    invalidateQueries();
  }, [loginMutation, invalidateQueries]);
  
  const logout = useCallback(async () => {
    const promise = logoutMutation.mutateAsync();
    toast.promise(promise, {
      loading: 'Logging out...',
      error: 'Logout failed',
    });
    await promise;
    invalidateQueries();
  }, [logoutMutation, invalidateQueries]);

  return {
    login,
    logout,
  }
}