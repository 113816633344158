import { useEffect, useState } from "react";

const SNIPPET_SRC = '//code.tidio.co/nq1ikljkmvvpvfte2tosyjnwtvgiu4fw.js';

const tidioLoaded = ():boolean => {
  try {
    return !!(window as any).tidioChatApi;
  } catch(err) {
    return false;
  }
}

const openTidio = () => {
  try {
    (window as any).tidioChatApi.open();
  } catch(err) {
    console.error('Tidio not loaded');
  }
}

const closeTidio = () => {
  try {
    (window as any).tidioChatApi.close();
  } catch(err) {
    console.error('Tidio not loaded');
  }
}

const hideTidio = () => {
  try {
    (window as any).tidioChatApi.hide();
  } catch(err) {
    console.error('Tidio not loaded');
  }
}

const showTidio = () => {
  try {
    (window as any).tidioChatApi.show();
  } catch(err) {
    console.error('Tidio not loaded');
  }
}

const getTidioStates = (): {
  key: string;
  state: any;
}[] => {
  try {
    const states: Array<{
      key: string;
      state: any;
    }> = [];
    for (var key in localStorage) {
      const match = key.startsWith('tidio_state_');
      if(match) {
        const val = localStorage.getItem(key);
        const parsed = JSON.parse(val as string);
        states.push({
          key,
          state: parsed,
        });
      }
    }
    return states;
  } catch(err) {
    return []
  }
}

const patchTidioState = () => {
  try {
    const matches = getTidioStates();
    for(const match of matches) {
      const { key, state } = match;
      const patched = {
        ...state,
        showBranding: false,
        showOldMessages: true,
        view: 'closed',
        isMounted: false,
      }
      localStorage.setItem(key, JSON.stringify(patched));
    }
  } catch(err) {
   // ignore 
  }
}

export const useTidio = () => {
  return {
    openTidio,
    closeTidio,
    hideTidio,
    showTidio,
  }
}

export const useLoadTidio = (email: string) => {
  const [loaded, setLoaded] = useState(false);
  const [injectedIdentity, setInjectedIdentity] = useState(false);
  const [injectedSnippet, setInjectedSnippet] = useState(false);

  useEffect(() => {
    patchTidioState();
  }, []);

  useEffect(() => {
    if(!injectedIdentity) {
      setInjectedIdentity(true);
      (document as any).tidioIdentify = {
        distinct_id: email,
        email,
      };
    }
  }, [injectedIdentity]);

  useEffect(() => {
    if(!injectedSnippet && injectedIdentity) {
      setInjectedSnippet(true);
      const script = document.createElement('script');
      script.src = SNIPPET_SRC;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [injectedSnippet, injectedIdentity]);

  useEffect(() => {
    const poll = setInterval(() => {
      if(tidioLoaded()) {
        setLoaded(true);
        clearInterval(poll);
      }
    }, 100);

    setTimeout(() => {
      // clear the poll after 10secs
      clearInterval(poll);
    }, 10000);

    return () => clearInterval(poll);
  }, []);

  useEffect(() => {
    if(loaded) {
      const poll = setInterval(() => {
        closeTidio();
        hideTidio();
      }, 100)
      setTimeout(() => {
        clearInterval(poll);
      }, 500);
    }
  }, [loaded]);
}