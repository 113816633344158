import { LayoutDefault } from '@client/components/layout-default'
import { TypographyH2 } from '@client/components/ui/custom/typography-h2'
import { TypographyLarge } from '@client/components/ui/custom/typography-large'
import { TypographyMuted } from '@client/components/ui/custom/typography-muted'
import { TypographyP } from '@client/components/ui/custom/typography-p'
import { Separator } from '@client/components/ui/separator'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@client/components/ui/tabs'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { PropsWithChildren } from 'react'
import { z } from 'zod'

const Section = (props: PropsWithChildren) => {
  return <div className="mt-8">{props.children}</div>
}

const UnorderedList = (props: PropsWithChildren) => {
  return <ul className='list-disc list-inside mt-2 ml-4'>{props.children}</ul>
}

const PrivacyPolicy = () => {
  return (
    <>
      <TypographyLarge className='font-bold mt-4'>Privacy Policy</TypographyLarge>
      <TypographyMuted>Last updated: October 2024</TypographyMuted>

      <Separator className='my-6' />

      <Section>
        <TypographyH2>1. Data Collection</TypographyH2>
        <TypographyP>
          We collect and store the following data to provide and enhance the Service:
        </TypographyP>
        <UnorderedList>
          <li>
            User Credentials: Login credentials to the Third-Party Portal provided by the User, which are used solely for authenticating and interacting with the portal on your behalf.
          </li>
          <li>
            Booking Information: Details of bookings retrieved from the Third-Party Portal, which are used to generate the ICS calendar feed and enable automated booking.
          </li>
          <li>
            Payment Information: If applicable, payment details collected and processed through our third-party payment processor, Stripe.
          </li>
          <li>
            Usage Data: Data related to your interaction with the Service (e.g., IP addresses, device information, and analytics data) collected through third-party analytics tools.
          </li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>2. Data Usage</TypographyH2>
        <TypographyP>
          We use the collected data to:
        </TypographyP>
        <UnorderedList>
          <li>Authenticate and log into the Third-Party Portal on your behalf.</li>
          <li>Retrieve booking information and generate an ICS calendar feed.</li>
          <li>Make automated bookings on your behalf using the provided credentials.</li>
          <li>Process payments for service subscriptions or other transactions using Stripe.</li>
          <li>Improve the Service through analytics and customer support.</li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>3. Third-Party Processors</TypographyH2>
        <TypographyP>
          We work with the following third-party processors to provide certain aspects of the Service:
        </TypographyP>
        <UnorderedList>
          <li>
            Tidio: We use Tidio for customer support and chat services. Tidio may collect your IP address, email (if provided), and any information you share through the chat interface.
          </li>
          <li>
            PostHog: We use PostHog for analytics and performance monitoring to understand how users interact with the Service. This helps us identify areas for improvement. Data collected includes interaction events, device information, and usage patterns.
          </li>
          <li>
            Stripe: We use Stripe for processing payments. Stripe collects payment information such as your name, card details, and billing address.
          </li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>4. Data Sharing and Disclosure</TypographyH2>
        <TypographyP>
          We do not share or disclose your data with any other third parties except for the listed third-party processors above, unless required by law or with your explicit consent.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>5. Data Security</TypographyH2>
        <TypographyP>
          User credentials and data are stored securely using industry-standard encryption and access control measures. We continuously monitor and update our security practices to protect your information. All interactions with third-party processors are governed by their own security and compliance measures.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>6. User Rights</TypographyH2>
        <TypographyP>
          Users can:
        </TypographyP>
        <UnorderedList>
          <li>Request access to their stored data.</li>
          <li>Modify their login credentials or any other provided data.</li>
          <li>Request data deletion and terminate the Service at any time by contacting us at hello.jhbooker@gmail.com.</li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>7. Cookies and Tracking Technologies</TypographyH2>
        <TypographyP>
          Our Service may use cookies or other tracking technologies for session management and to improve user experience. You can control the use of cookies through your browser settings.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>8. Changes to this Privacy Policy</TypographyH2>
        <TypographyP>
          We may update this Privacy Policy as necessary. Any changes will be communicated through the Service or via email. Continued use of the Service after any changes are made indicates your acceptance of the updated Privacy Policy.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>9. Contact Information</TypographyH2>
        <TypographyP>
          For questions or concerns about this Privacy Policy, contact us at hello.jhbooker@gmail.com.
        </TypographyP>
      </Section>
    </>
  )
}


const Terms = () => {
  return (
    <>
      <TypographyLarge className='font-bold mt-4'>Terms of Service</TypographyLarge>
      <TypographyMuted>Last updated: October 2024</TypographyMuted>

      <Separator className='my-6' />

      <Section>
        <TypographyH2>1. Acceptance of Terms</TypographyH2>
        <TypographyP>
          By using this service ("Service"), you ("User") agree to these Terms
          of Service ("Terms"). If you do not agree, do not use the Service.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>2. Service Description</TypographyH2>
        <TypographyP>
          The Service is provided by an independent individual ("The Creator")
          and offers the following functionalities:
        </TypographyP>
        <UnorderedList>
          <li>
            A calendar feed that integrates with your personal calendar to show
            your Club bookings. The "Club" refers to Jesse's or Jaego's House.
          </li>
          <li>
            An automated booking engine that can schedule classes or activities
            on your behalf using your login credentials to the Club's portal
            ("Third-Party Portal").
          </li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>3. User Responsibilities</TypographyH2>
        <TypographyP>
          Login Credentials: Users provide their own login credentials for the
          Third-Party Portal. By doing so, you authorize the Service to access
          and interact with the Third-Party Portal on your behalf.
        </TypographyP>
        <TypographyP>
          Data Sharing and Access: You confirm that you have the right to
          provide these credentials and understand the potential risks
          associated with sharing access.
        </TypographyP>
        <TypographyP>
          Changes to Membership: You understand that using this Service could
          potentially conflict with the Club's terms or policies. The Creator is
          not responsible for any actions taken by the Club, including, but not
          limited to, suspension or termination of your membership.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>4. Limitation of Liability</TypographyH2>
        <TypographyP>
          The Service is provided "as is" without any warranties or guarantees.
          The Creator is not liable for:
        </TypographyP>
        <UnorderedList>
          <li>
            Any loss of membership, penalties, or fines imposed by the Club due
            to the use of this Service.
          </li>
          <li>
            Any unauthorized access or misuse of credentials if the User's
            credentials are shared with or accessed by third parties outside of
            the Service.
          </li>
          <li>
            Any errors or inaccuracies in the bookings made on your behalf
            through the automated booking engine.
          </li>
        </UnorderedList>
      </Section>

      <Section>
        <TypographyH2>5. Relationship with Third-Party Portal</TypographyH2>
        <TypographyP>
          The Creator has no affiliation with the Club and does not represent,
          warrant, or endorse any aspects of the Club. The Creator is not liable
          for any changes in the Club's terms, policies, or functionality that
          may impact the Service.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>6. User Data and Privacy</TypographyH2>
        <TypographyP>
          Anonymity: The Creator will not disclose any personally identifiable
          information about users unless legally required.
        </TypographyP>
        <TypographyP>
          Data Handling: User credentials are stored and used securely only to
          perform the functionalities described and are not shared with any
          third parties other than those specified in the Privacy Policy.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>7. Indemnification</TypographyH2>
        <TypographyP>
          You agree to indemnify and hold The Creator harmless from any claims,
          damages, or legal expenses arising out of or related to your use of
          the Service, including any actions taken by the Third-Party Portal as
          a result of your use of the Service.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>8. Termination</TypographyH2>
        <TypographyP>
          The Creator reserves the right to terminate or suspend access to the
          Service at any time, without notice, for conduct that violates these
          Terms or is harmful to other users or the integrity of the Service.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>9. Changes to Terms</TypographyH2>
        <TypographyP>
          The Creator may modify these Terms at any time. Changes will be
          communicated to users through the Service or via email. Continued use
          of the Service after changes are posted constitutes acceptance of the
          revised Terms.
        </TypographyP>
      </Section>

      <Section>
        <TypographyH2>10. Governing Law</TypographyH2>
        <TypographyP>
          These Terms are governed by and construed in accordance with the laws
          of England and Wales. Any disputes arising under or in connection with
          these Terms will be subject to the exclusive jurisdiction of the
          courts of England and Wales.
        </TypographyP>
      </Section>
    </>
  )
}

enum Tab {
  TERMS = 'terms',
  PRIVACY = 'privacy',
}

const Component = () => {
  const { tab } = Route.useSearch();
  const navigate = useNavigate({
    from: Route.id,
  });
  return (
    <LayoutDefault>
      <Tabs value={tab} onValueChange={(value) => navigate({ search: { tab: value as Tab } })}>
        <TabsList>
          <TabsTrigger value={Tab.TERMS}>Terms of Service</TabsTrigger>
          <TabsTrigger value={Tab.PRIVACY}>Privacy Policy</TabsTrigger>
        </TabsList>
        <TabsContent value={Tab.TERMS}>
          <Terms />
        </TabsContent>
        <TabsContent value={Tab.PRIVACY}>
          <PrivacyPolicy />
        </TabsContent>
      </Tabs>
    </LayoutDefault>
  )
}

export const Route = createFileRoute('/legal')({
  component: Component,
  validateSearch: z.object({
    tab: z.nativeEnum(Tab).default(Tab.TERMS).catch(Tab.TERMS),
  }),
})
