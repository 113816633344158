import { Button, ButtonProps } from '@client/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@client/components/ui/command';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { SelectProps, SelectOption } from './types';
import { Drawer, DrawerContent, DrawerTrigger } from '@client/components/ui/drawer';
import { FormControl } from '@client/components/ui/form';
import { Input } from '@client/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@client/components/ui/popover';
import { Skeleton } from '@client/components/ui/skeleton';
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@client/components/ui/tooltip';
import { useScreenSize } from '@client/lib/use-screen-size';
import { cn } from '@client/lib/utils';
import { ChevronsUpDown, Check, XCircle, PlusCircle } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DialogScreenReaderInfo } from '@client/components/ui/dialog';

export type { SelectProps } from './types';

// ------ ADD OPTION POPOVER ------

export const OptionAddPopover = (props: { open: boolean; onOpenChange: (open: boolean) => void }) => {
  const { open, onOpenChange } = props;

  return (
    <Popover open={open} onOpenChange={onOpenChange} modal>
      <Input />
    </Popover>
  );
};

// ------- BTN IMPLEMENTATION -------

const OptionAsBtn = (
  props: SelectOption & {
    selected: boolean;
    customProps: SelectProps['optionsAsBtnsProps'];
    onClick: (value: string) => void;
  }
) => {
  const { selected, customProps = {}, value, description, icon, label, onClick } = props;
  const { size = 'sm', badge = true, variant = 'outline', selectedVariant = 'default', className } = customProps;

  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  const ref = useRef<HTMLButtonElement>(null);

  // we use this to disable automatic focus on the button in forms
  // this causes the tooltip to open and worsen the UX
  useEffect(() => {
    if (ref.current) {
      ref.current.blur();
    }
  }, [ref.current]);

  const btnRendered = useMemo(() => {
    return (
      <Button
        ref={ref}
        key={value}
        type="button"
        className={cn(className, {
          'rounded-full': badge,
          '': selected,
        })}
        size={size}
        variant={selected ? selectedVariant : variant}
        onClick={handleClick}>
        {icon}
        <span className="max-w-40 truncate">{label ?? value}</span>
      </Button>
    );
  }, [value, label, selected, handleClick]);

  if (description) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{btnRendered}</TooltipTrigger>
          <TooltipContent>{description}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return btnRendered;
};

const GroupAsBtns = (props: {
  name: string | undefined;
  options: SelectOption[];
  value: string;
  onSelect: (value: string) => void;
  onAddNew: (() => void) | undefined;
  optionsAsBtnsProps: NonNullable<SelectProps['optionsAsBtnsProps']>;
}) => {
  const { name, options, value, onSelect, onAddNew, optionsAsBtnsProps } = props;
  return (
    <div>
      {!!name && (
        <div className="text-sm text-muted-foreground text-xs font-bold border-b mb-2 border-border">{name}</div>
      )}
      <ButtonGroup className="mt-0">
        {options.map((opt) => (
          <OptionAsBtn
            key={opt.value}
            onClick={onSelect}
            selected={value === opt.value}
            customProps={optionsAsBtnsProps}
            {...opt}
          />
        ))}
        {onAddNew && (
          <OptionAsBtn
            value={'$add$'}
            label=""
            icon={<PlusCircle className="h-4 w-4" />}
            onClick={onAddNew}
            selected={false}
            customProps={{
              ...optionsAsBtnsProps,
              size: 'icon',
              variant: 'ghost',
            }}
          />
        )}
      </ButtonGroup>
    </div>
  );
};

const SelectAsBtns = (
  props: Pick<SelectProps, 'selected' | 'options' | 'allowClear' | 'onChange' | 'onAddNew' | 'optionsAsBtnsProps'>
) => {
  const { selected, options, allowClear = false, onChange, onAddNew, optionsAsBtnsProps } = props;

  // we need this because the underlying component converts values to lowercase
  const optionValuesMap = useMemo(
    () => Object.fromEntries(options.map((opt) => [opt.value.toLowerCase(), opt.value])),
    [options]
  );

  const handleChange = useCallback(
    (_value: string | undefined) => {
      const value = (_value && optionValuesMap[_value]) ?? _value;
      if (selected?.value === value && allowClear) {
        onChange(undefined);
      } else {
        // we don't want empty strings
        onChange(value || undefined);
      }
    },
    [onChange, optionValuesMap, selected, allowClear]
  );

  const renderedGroups = useMemo(() => {
    const optionsByGroup = options.reduce<Record<string, SelectOption[]>>((acc, opt) => {
      if (opt.group) {
        if (!acc[opt.group]) {
          acc[opt.group] = [];
        }
        acc[opt.group].push(opt);
      }
      return acc;
    }, {});

    const groups = Object.keys(optionsByGroup);
    const hasGroups = groups.length > 0 && options.length > 5;
    const otherGroup = hasGroups && options.filter((opt) => !opt.group);
    return (
      <div className={'flex flex-col gap-3'}>
        {hasGroups ? (
          <>
            {groups.map((group, i) => (
              <GroupAsBtns
                key={i}
                name={group}
                options={optionsByGroup[group]}
                value={selected?.value ?? ''}
                onSelect={handleChange}
                onAddNew={onAddNew}
                optionsAsBtnsProps={optionsAsBtnsProps!}
              />
            ))}
            {otherGroup && otherGroup.length > 0 && (
              <GroupAsBtns
                name="Other"
                options={otherGroup}
                value={selected?.value ?? ''}
                onSelect={handleChange}
                onAddNew={onAddNew}
                optionsAsBtnsProps={optionsAsBtnsProps!}
              />
            )}
          </>
        ) : (
          <GroupAsBtns
            options={options}
            value={selected?.value ?? ''}
            onSelect={handleChange}
            onAddNew={onAddNew}
            name={undefined}
            optionsAsBtnsProps={optionsAsBtnsProps!}
          />
        )}
      </div>
    );
  }, [options, selected, handleChange, onAddNew, optionsAsBtnsProps]);

  return renderedGroups;
};

// ------- DEFAULT IMPLEMENTATION -------

const OptionDefault = (props: SelectOption) => {
  const { isMobile } = useScreenSize();
  return (
    <div
      className={cn('font-normal flex items-center', {
        'px-1 py-1': isMobile,
      })}>
      {props.icon && (
        <div
          className={cn('mr-4', {
            'mr-4': isMobile,
          })}>
          {props.icon}
        </div>
      )}
      <div className="text-left">
        <span className="truncate">{props.label ?? props.value}</span>
        {props.description && (
          <div className="truncate text-muted-foreground text-xs mt-[1px]">{props.description}</div>
        )}
      </div>
    </div>
  );
};

const GroupDefault = (props: {
  name: string | undefined;
  options: SelectOption[];
  value: string;
  onSelect: (value: string) => void;
}) => {
  const { name, options, value, onSelect } = props;
  return (
    <CommandGroup heading={name}>
      {options.map((opt) => (
        <CommandItem value={opt.value} key={opt.value} onSelect={onSelect}>
          <OptionDefault {...opt} />
          <Check className={cn('ml-auto h-4 w-4', opt.value === value ? 'opacity-100' : 'opacity-0')} />
        </CommandItem>
      ))}
    </CommandGroup>
  );
};

const SelectDefault = (props: Omit<SelectProps, 'optionsAsBtns'>) => {
  const {
    selected,
    options,
    placeholder = 'Select an option',
    placeholderIcon,
    searchPlaceholder = 'Search...',
    searchTerm,
    noResultsMessage = 'No results found',
    loading = false,
    asBtn = false,
    disabled = false,
    inForm = false,
    allowClear = false,
    closeOnChange = true,
    alwaysShowSearch = false,
    disableSearch = false,
    placeholderIconPosition = 'left',
    drawerOpts = {},
    popoverOpts = {},
    noDrawer = false,
    onChange,
    onAddNew,
    onSearchChange,
  } = props;

  const asBtnProps = useMemo<ButtonProps>(
    () => ({
      variant: 'outline',
      size: placeholder ? 'default' : 'icon',
      ...props.asBtnProps,
    }),
    [props.asBtnProps, placeholder]
  );

  const { isMobile } = useScreenSize();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(searchTerm ?? '');

  // we need this because the underlying component converts values to lowercase
  const optionValuesMap = useMemo(
    () => Object.fromEntries(options.map((opt) => [opt.value.toLowerCase(), opt.value])),
    [options]
  );

  const shouldHideSearch = useMemo(() => {
    if (disableSearch) {
      return true;
    }
    return !alwaysShowSearch && !onSearchChange && options.length < 5;
  }, [alwaysShowSearch, disableSearch, onSearchChange, options]);

  const handleChange = useCallback(
    (_value: string | undefined) => {
      const value = (_value && optionValuesMap[_value]) ?? _value;
      // console.log({ value, _value });
      // we don't want empty strings
      onChange(value || undefined);
      if (closeOnChange) {
        setOpen(false);
      }
    },
    [onChange, closeOnChange, optionValuesMap]
  );

  const handleClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  // sync search term with parent
  useEffect(() => {
    setSearch(searchTerm ?? '');
  }, [searchTerm]);

  useEffect(() => {
    // avoid empty strings
    onSearchChange?.(search || undefined);
  }, [search, onSearchChange]);

  // clear search when closed
  useEffect(() => {
    if (!open) {
      setSearch('');
    }
  }, [open]);

  const filteredOptions = useMemo(() => {
    return options.filter(
      (opt) =>
        !search ||
        `${opt.label} ${opt.description ?? ''} ${opt.group ?? ''}`.toLowerCase().includes(search.toLowerCase())
    );
  }, [options, search]);

  const renderedGroups = useMemo(() => {
    const optionsByGroup = filteredOptions.reduce<Record<string, SelectOption[]>>((acc, opt) => {
      if (opt.group) {
        if (!acc[opt.group]) {
          acc[opt.group] = [];
        }
        acc[opt.group].push(opt);
      }
      return acc;
    }, {});

    const groups = Object.keys(optionsByGroup);
    const hasGroups = groups.length > 0;
    const otherGroup = hasGroups && filteredOptions.filter((opt) => !opt.group);
    return (
      <>
        {hasGroups ? (
          <>
            {groups.map((group, i) => (
              <React.Fragment key={group}>
                <GroupDefault
                  name={group}
                  options={optionsByGroup[group]}
                  value={selected?.value ?? ''}
                  onSelect={handleChange}
                />
                {i < groups.length - 1 && <CommandSeparator />}
              </React.Fragment>
            ))}
            {otherGroup && otherGroup.length > 0 && (
              <GroupDefault name="Other" options={otherGroup} value={selected?.value ?? ''} onSelect={handleChange} />
            )}
          </>
        ) : (
          <GroupDefault
            options={filteredOptions}
            value={selected?.value ?? ''}
            onSelect={handleChange}
            name={undefined}
          />
        )}

        {(onAddNew || (allowClear && selected)) && (
          <>
            <CommandSeparator />
            <CommandGroup>
              {selected && allowClear ? (
                <CommandItem value={'$clear$'} onSelect={handleClear}>
                  <XCircle className="mr-2 h-4 w-4" />
                  Clear Selection
                </CommandItem>
              ) : (
                <CommandItem value={'$add$'} onSelect={onAddNew}>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Add New
                </CommandItem>
              )}
            </CommandGroup>
          </>
        )}
      </>
    );
  }, [filteredOptions, selected, handleChange, onAddNew]);

  const renderedButton = useMemo(() => {
    // is inForm, wrap in FormControl, otherwise wrap in Button
    const btn = asBtn ? (
      <Button {...asBtnProps} role="combobox">
        {placeholderIconPosition === 'left' && placeholderIcon}
        {placeholder}
        {placeholderIconPosition === 'right' && placeholderIcon}
      </Button>
    ) : (
      // this style makes it look like a normal select (the default)
      <Button
        variant="outline"
        role="combobox"
        disabled={disabled}
        className={cn('w-full h-full justify-between', !selected && 'text-muted-foreground font-normal')}>
        {selected?.value ? <OptionDefault {...selected} /> : placeholder}
        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    );
    if (inForm) {
      return <FormControl>{btn}</FormControl>;
    } else {
      return btn;
    }
  }, [selected, placeholder, inForm, asBtn, asBtnProps]);

  const renderedCommand = useMemo(() => {
    return (
      <Command shouldFilter={false} className={`w-full block overflow-y-scroll`} autoFocus={false}>
        {!shouldHideSearch && (
          <CommandInput
            value={search}
            onValueChange={setSearch}
            placeholder={searchPlaceholder}
            className="h-9"
            loading={loading}
          />
        )}

        <CommandList>
          {!loading && <CommandEmpty>{noResultsMessage}</CommandEmpty>}
          {options.length === 0 &&
            (loading ? (
              <div className="px-1 py-2">
                <Skeleton className="w-[40%] h-[12px] rounded-sm" />
                <Skeleton className="w-full h-[30px] rounded-md mt-1" />
              </div>
            ) : (
              <div className="py-6 text-center text-sm">{noResultsMessage}</div>
            ))}
          {renderedGroups}
        </CommandList>
      </Command>
    );
  }, [search, searchPlaceholder, noResultsMessage, renderedGroups, loading]);

  if (isMobile && !noDrawer) {
    const { fullHeight = false } = drawerOpts;
    return (
      <Drawer open={open} onOpenChange={setOpen} shouldScaleBackground={false}>
        <DrawerTrigger asChild>{renderedButton}</DrawerTrigger>
        <DrawerContent
          addClose
          className={cn(`pb-8 min-h-[200px]`, {
            'min-h-[200px]': !fullHeight,
            'h-[97dvh]': fullHeight,
          })}>
          <DialogScreenReaderInfo title={placeholder} />

          {renderedCommand}
        </DrawerContent>
      </Drawer>
    );
  } else {
    const {
      align = 'start',
      avoidCollisions = true,
      alignOffset,
      className,
      side = 'bottom',
      sideOffset,
    } = popoverOpts;
    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>{renderedButton}</PopoverTrigger>
        <PopoverContent
          align={align}
          alignOffset={alignOffset}
          side={side}
          sideOffset={sideOffset}
          avoidCollisions={avoidCollisions}
          className={cn(asBtn ? 'min-w-[200px] p-0' : `w-[var(--radix-popover-trigger-width)] p-0`, className)}>
          {renderedCommand}
        </PopoverContent>
      </Popover>
    );
  }
};

export const Select = (props: SelectProps) => {
  const optionsAsBtns = useMemo(() => {
    return (
      props.optionsAsBtns === true ||
      (typeof props.optionsAsBtns === 'number' && props.options.length <= props.optionsAsBtns)
    );
  }, [props.optionsAsBtns, props.options]);

  if (optionsAsBtns) {
    return (
      <SelectAsBtns
        options={props.options}
        selected={props.selected}
        allowClear={props.allowClear}
        onChange={props.onChange}
        onAddNew={props.onAddNew}
        optionsAsBtnsProps={{
          badge: props.optionsAsBtnsProps?.badge ?? true,
          size: props.optionsAsBtnsProps?.size ?? 'sm',
        }}
      />
    );
  }
  return <SelectDefault {...props} />;
};