// breakpoints
export const BREAKPOINT_SM = 640;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 1024;
export const BREAKPOINT_XL = 1280;
export const BREAKPOINT_2XL = 1400;

// dates
// eslint-disable-next-line @typescript-eslint/naming-convention
export const yyyy_MM_dd = 'yyyy-MM-dd'; // for use with date-fns

export const EMOJI_SELECTOR_HINT_TEXT = `Tip: Emoji selector, press Windows (⊞) + Period (.)`;