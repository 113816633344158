import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/globals.css';
import './styles/custom.css';

import App from './app';

import posthog from 'posthog-js';
// @ts-ignore
import { PostHogProvider } from 'posthog-js/react';
import { ThemeProvider } from './theme';
import { config } from './config';

if (config.posthogKey && config.posthogHost) {
  posthog.init(config.posthogKey, {
    api_host: config.posthogHost,
    autocapture: false,
    capture_heatmaps: true,
    capture_performance: true,
    session_recording: {
      maskCapturedNetworkRequestFn(request) {
        return request;
      },
      maskInputFn(text) {
        return text;
      },
      maskTextFn(text) {
        return text;
      },
    },
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ThemeProvider defaultTheme="system">
        <App />
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>
);