import { createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_THEME, Theme } from './theme';
import { useMediaQuery } from 'usehooks-ts';

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  theme?: Theme; // use for controlled mode
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  isDarkMode: boolean;
};

const MS_TEAMS_THEME_TO_THEME: Record<string, Theme> = {
  default: 'light',
  light: 'light',
  dark: 'dark',
  contrast: 'dark',
  system: 'system',
};

const initialState: ThemeProviderState = {
  theme: DEFAULT_THEME,
  isDarkMode: false,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export const ThemeProvider = ({
  children,
  defaultTheme = DEFAULT_THEME,
  storageKey = 'vite-ui-theme',
  ...props
}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(props.theme || (localStorage.getItem(storageKey) as Theme) || defaultTheme);

  // TODO: stop theme flash on dark mode
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (props.theme) {
      setTheme(props.theme);
    }
  }, [props.theme]);

  // apply theme changes to DOM
  useEffect(() => {
    // remove current theme annotation
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    // calc new theme
    const newTheme = theme === 'system' ? (isDarkMode ? 'dark' : 'light') : theme;
    root.classList.add(newTheme);
  }, [isDarkMode, theme]);

  const value = { theme, isDarkMode };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};
