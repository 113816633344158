import { cn } from '@client/lib/utils';
import React from 'react';

export function TypographyH2(props: React.HTMLAttributes<HTMLElement>) {
  return (
    <h2 {...props} className={cn('scroll-m-20 text-3xl font-semibold', props.className)}>
      {props.children}
    </h2>
  );
}
