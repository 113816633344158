import { trpc } from "@client/lib/trpc"
import { useCallback } from "react";
import { toast } from "sonner";


export const useBilling = () => {

  const trpcUtils = trpc.useUtils();

  const goToCheckout = useCallback(async () => {
    const checkoutUrlPromise = trpcUtils.getCheckoutSession.fetch();
    toast.promise(checkoutUrlPromise, {
      loading: 'Redirecting you to Stripe...',
    });
    const checkoutUrl = await checkoutUrlPromise;
    if(checkoutUrl) {
      window.location.href = checkoutUrl;
    } else {
      toast.error('Failed to go to checkout');
    }
  }, []);

  const goToBillingPortal = useCallback(async () => {
    const billingPortalUrlPromise = trpcUtils.getBillingPortalUrl.fetch();
    toast.promise(billingPortalUrlPromise, {
      loading: 'Redirecting you to Stripe...',
    });
    const billingPortalUrl = await billingPortalUrlPromise;
    if(billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  }, []);

  return {
    goToCheckout,
    goToBillingPortal,
  }
}