import { cn } from '@client/lib/utils';

export const Emoji = (
  props: React.HTMLAttributes<HTMLElement> & {
    sm?: boolean;
    lg?: boolean;
    xl?: boolean;
  }
) => {
  const { sm = false, lg = false, xl = false, ...rest } = props;
  return (
    <span
      {...rest}
      className={cn(rest.className, {
        'text-xl': !sm && !lg && !xl,
        'text-2xl': lg,
        'text-4xl': xl,
      })}
      role="img"
      aria-label="emoji">
      {props.children}
    </span>
  );
};
