import { trpc } from "@client/lib/trpc";
import { useCallback } from "react";


export const useCalendar = (onInvalidate?: () => void) => {

  const toggleCalendarMutation = trpc.calendar.toggle.useMutation();

  const invalidateQueries = useCallback(() => {
    onInvalidate?.();
  }, [onInvalidate]);

  const toggleCalendar = useCallback(async (enabled: boolean) => {
    await toggleCalendarMutation.mutateAsync({ enabled });
    invalidateQueries();
  }, [toggleCalendarMutation, invalidateQueries]);

  return {
    toggleCalendar,
  };
}