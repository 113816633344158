import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function parseDateStr<T>(dateStr: T): T extends string ? Date : T {
  if (typeof dateStr !== 'string') {
    return dateStr as any;
  }
  return new Date(dateStr) as any;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function numBetween(a: number, b: number) {
  return Math.round(Math.random() * (b - a)) + a;
}

const SMALL_WORDS =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
const TOKENS = /[^\s:–—-]+|./g;
const WHITESPACE = /\s/;
const IS_MANUAL_CASE = /.(?=[\p{Lu}]|\..)/u;
const ALPHANUMERIC_PATTERN = /[\p{Lu}\p{Ll}\d]/u;
export const titleCase = (input: string, locale?: string[] | string): string => {
  let result = '';
  let m;
  // tslint:disable-next-line
  while ((m = TOKENS.exec(input)) !== null) {
    const { 0: token, index } = m;
    if (
      // Ignore already capitalized words.
      !IS_MANUAL_CASE.test(token) &&
      // Ignore small words except at beginning or end.
      (!SMALL_WORDS.test(token) || index === 0 || index + token.length === input.length) &&
      // Ignore URLs.
      (input.charAt(index + token.length) !== ':' || WHITESPACE.test(input.charAt(index + token.length + 1)))
    ) {
      // Find and uppercase first word character, skips over *modifiers*.
      result += token.replace(ALPHANUMERIC_PATTERN, (m) => m.toLocaleUpperCase(locale));
      continue;
    }
    result += token;
  }
  return result;
};

export function getInitials(name?: string): string {
  try {
    return (name ?? '')
      .trim()
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .split(' ')
      .filter((part) => part) // Remove empty parts that might result from spaces
      .map((part) => part[0].toUpperCase()) // Take the first character of each part and make it uppercase
      .join('')
      .substring(0, 2); // Limit to two characters
  } catch (err) {
    return '';
  }
}

export const splitTextAndEmojis = (input: string): (string | { emoji: string; isModifier?: boolean })[] => {
  const emojiRegex =
    /([\u{1F3FB}-\u{1F3FF}]|[\u{1F1E6}-\u{1F1FF}][\u{1F1E6}-\u{1F1FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F300}-\u{1F5FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}])/gu;

  let result: (string | { emoji: string; isModifier?: boolean })[] = [];
  let lastIndex = 0;

  input.replace(emojiRegex, (...args) => {
    const [match] = args;
    const offset = args.find((arg) => typeof arg === 'number') as number;
    if (lastIndex !== offset) {
      result.push(input.substring(lastIndex, offset));
    }
    const isModifier = match.match(/[\u{1F3FB}-\u{1F3FF}]|\u{200D}|\u{2640}/u) ? true : false;
    result.push({ emoji: match, isModifier });
    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex !== input.length) {
    result.push(input.substring(lastIndex));
  }
  // console.log(JSON.stringify({ input, result }));
  result = result.map((x) => (typeof x === 'string' ? x.trim() : x));
  // console.log(JSON.stringify({ input, result }));
  return result;
};